import React from 'react';
import { makeStyles, Button, colors } from "@material-ui/core";
import { theme } from '../styles/theme-constants';

export const useStylesBack = makeStyles({
    button: {
        color: "white",
        textTransform: "none",
        padding: '0 40px',
        fontSize: '14px',
        fontWeight: '700',
        borderRadius: '5px',
        height: '42px',
        backgroundColor: theme.purpleButton,
        '&:hover': {
            backgroundColor: theme.white,
            border:`1px solid ${theme.purpleButton}`,
            color:theme.purpleButton,
            boxShadow:"rgba(0, 0, 0, 0.13) 0px 1px 1px"
        }
    }
});

export const useStylesGo = makeStyles({
    button: {
        color: "white",
        textTransform: "none",
        padding: '0 40px',
        fontSize: '14px',
        fontWeight: 'bold',
        borderRadius: '5px',
        height: '42px',
        backgroundColor: theme.primary,
        '&:hover': {
            backgroundColor: theme.white,
            border:`1px solid ${theme.primary}`,
            color:theme.primary,
            boxShadow:"rgba(0, 0, 0, 0.13) 0px 1px 1px"
        },
        "@media (max-width: 425px)": {
            width: '100% !important',
        },
        "&:disabled":{
            backgroundColor: theme.grayLight,
            colors:theme.gray
        }
    },
    buttonMobileCard: {
        color: "white",
        textTransform: "none",
        padding: '6px 8px',
        fontSize: '14px',
        height:'42px',
        fontWeight: 'bold',
        borderRadius: '5px',
        backgroundColor: theme.primary,
        '&:hover': {
            backgroundColor: theme.white,
            border:`1px solid ${theme.primary}`,
            color:theme.primary,
            boxShadow:"rgba(0, 0, 0, 0.13) 0px 1px 1px"
        },
        "@media (max-width: 425px)": {
            width: '100% !important',
        },
        "&:disabled":{
            backgroundColor: theme.grayLight,
            colors:theme.gray
        }
    }
});

const GeneralButton = ({ button, disabled, width, fontSize, type, href, mobile=false }) => {
    const classesBack = useStylesBack();
    const classesGo = useStylesGo();
    return (
        <Button
            disabled={disabled}
            type={type ? type : "button"}
            id="btn-general"
            href={href ? href : ""}
            color="primary" className={button.type === "back" ? 
                classesBack.button : 
                mobile ? classesGo.buttonMobileCard : 
                classesGo.button
            }
            onClick={button.func ? button.func : null}
            style={{ 
                width: width ? width : null, 
                fontSize: fontSize && fontSize, 
             }}>
            {button.icon ? button.icon : null}
            {button.text}
        </Button>
    );
}

export default GeneralButton;