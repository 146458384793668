const PROTOCOL = process.env.APP_PROTOCOL;
const APP_HOST_NAME = process.env.APP_HOST_NAME;
import * as Sentry from '@sentry/nextjs';
import axios, { get, post } from "axios";
import Error from "next/error";
import { returnMessageError } from '../constants/errorMessages';
import {
  finishLoginProgress,
  isLoading,
  isLoginSuccessfull,
  openSnackbar,
  resetLoginProgress,
  setChangePasswordModal,
  setErrorAtLogin,
  setErrorAtSignupField,
  setLoginProgress,
  setSignupFormValidation,
  willLogIn
} from '../context/actions';
import { ACCOUNT_STEP, finishCreation } from "../lib/signup";
import { trackLogIn, trackSignUp } from "../tracking/users";
import { getJwtToken, getJwtTokenFromClient, removeCookies } from './cookies';

const collectionName = 'user-details';

export async function createUser(object) {
  return await post(
    `${PROTOCOL}://${APP_HOST_NAME}/api/auth/local/register`,
    object).then((response) => {
          const registrationResponse = response.data;

          delete registrationResponse.jwt;
          return response
        }).catch((error) => {
          Sentry.captureException('Error in auth register endpoint', error);
          return error.response;
        });
    }

// Handle Signup errors
export const handleCreateUserResponse = (dispatch, response) => {
  isLoading(dispatch, { loading: false });
  if (response) {
    switch (response.status) {
      case 200:
        openSnackbar(dispatch, {
          isOpen: true,
          message: "Cuenta creada con éxito",
          severity: "success",
        });
        trackSignUp(response.data);
        finishCreation(dispatch);
        break;
      case 400:
        try {
          if (response.data.data[0].messages[0].id === "Auth.form.error.email.taken") {
            setAccountAlreadyExists(dispatch);
          }
        } catch (err) {
          throw new Error("Unexpected sign up error");
          // TODO: most probably `cannot read property messages of undefined`
          // when an error happpens in this point, usually, it wont have user_details created
        }
        openSnackbar(dispatch, {
          isOpen: true,
          message: returnMessageError(response.data),
          severity: "error",
        });
        break;
      case 500:
        resetLoginProgress(dispatch);
        setErrorAtLogin(dispatch, returnMessageError('Hubo un error al enviar tus datos. Inténtalo nuevamente. Si el error persiste, manda un correo a soporte@vincoed.com'));
        break;
      default:
        openSnackbar(dispatch, {
          isOpen: true,
          message: "Hubo un error al enviar tus datos. Inténtalo nuevamente. Si el error persiste, manda un correo a soporte@vincoed.com",
          severity: "error",
        });
        break;
    }
  } else {
    resetLoginProgress(dispatch);
    setErrorAtLogin(dispatch, returnMessageError('Hubo un error al enviar tus datos. Inténtalo nuevamente. Si el error persiste, manda un correo a soporte@vincoed.com'));
  }
}

const setAccountAlreadyExists = (dispatch) => {
  setErrorAtSignupField(dispatch, { id: 'username', value: true });
  setErrorAtSignupField(dispatch, { id: 'email', value: true });
  setSignupFormValidation(dispatch, { name: ACCOUNT_STEP, value: false })
}

// Log in request // AS IT IS CLIENT SIDE IT CANNOT USE ENV VARIABLES
export async function login(dispatch, loginInfo) {
  return await post(
    `${PROTOCOL}://${APP_HOST_NAME}/api/auth/local`,
    { ...loginInfo },
    {
      onUploadProgress: (progressEvent) => {
        let percentCompleted = Math.floor((progressEvent.loaded * 100) / progressEvent.total);
        setLoginProgress(dispatch, percentCompleted);
      }
    }
  ).then((response) => {
    return response;
  }).catch((error) => {
    Sentry.captureException('Error in auth login endpoint', error);
    return error.response;
  });
}

// Log out method TODO: Logout against token
export async function logout() {
  return await get(`${PROTOCOL}://${APP_HOST_NAME}/api/logout`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
}

// Get user profile
export async function getUserProfile(ctx) {
  return await get(
    `${PROTOCOL}://${APP_HOST_NAME}/api/user-details/info/profile`,
    {
      headers: {
        'AUTHORIZATION': `Bearer ${getJwtToken(ctx)}`
      }
    }
  ).then((response) => response.data)
}

// Get user benefits
export async function getUserBenefits(ctx) {
  return await get(
    `${PROTOCOL}://${APP_HOST_NAME}/api/benefits/me`,
    {
      headers: {
        'AUTHORIZATION': `Bearer ${getJwtToken(ctx)}`
      }
    }
  ).then((response) => {
    
    return {
      ...response.data
    };
  }).catch((e) => {
    ctx.res.setHeader("location", "/login");
    ctx.res.statusCode = 302;
    ctx.res.end();
  });
}

// Get user benefits page (my benefits)
export async function getBenefitsPage(ctx) {
  return await get(
    `${PROTOCOL}://${APP_HOST_NAME}/api/pages/benefits`,
    {
      headers: {
        'AUTHORIZATION': `Bearer ${getJwtToken(ctx)}`
      }
    }
  ).then((response) => {
    return {
      ...response.data.benefits
    };
  }).catch((e) => {
    Sentry.captureException('Error getting benefits page endpoint', e);
    ctx.res.setHeader("location", "/login");
    ctx.res.statusCode = 302;
    ctx.res.end();
  });
}

// Get user applications page (my applications)
export async function getApplicationsPage(ctx) {
  return await get(
    `${PROTOCOL}://${APP_HOST_NAME}/api/pages/applications`,
    {
      headers: {
        'AUTHORIZATION': `Bearer ${getJwtToken(ctx)}`
      }
    }
  )
}

// TODO: #BOOKMARKREFACTOR
export async function getSavedBookmarks(ctx) {
  return await get(
    `${PROTOCOL}://${APP_HOST_NAME}/api/user-details/savedcourses`,
    {
      headers: {
        'AUTHORIZATION': `Bearer ${getJwtToken(ctx)}`
      }
    }
  ).then((response) => response.data).catch((e) => {
    Sentry.captureException('Error getting saved bookmarks - endpoint', e);
    console.error('error', e);
  });
}

export async function getAllFavorites( { tokenUser }) {
  return await get(
    `${PROTOCOL}://${APP_HOST_NAME}/api/user-details/savedcourses`,
    {
      headers: {
        'AUTHORIZATION': `Bearer ${tokenUser}`
      }
    }
  ).then((response) => response.data).catch((e) => {
    Sentry.captureException('Error getting saved bookmarks - endpoint', e);
    console.error('error', e);
  });
}

export async function getIsFavorite({ ctx, courseId }) {
  return await get(
    `${PROTOCOL}://${APP_HOST_NAME}/api/user-details/favorites/${courseId}`,
    {
      headers: {
        'AUTHORIZATION': `Bearer ${getJwtToken(ctx)}`
      }
    }
  ).then((response) => response.data).catch((e) => {
    Sentry.captureException('Error getting saved bookmarks - endpoint', e);
    console.error('error', e);
  });
}

export async function editUserDetails(dispatch, object, userDetailId) {
  const token = getJwtTokenFromClient().authToken;
  isLoading(dispatch, { loading: true });
  return await axios({
    method: 'put',
    url: `${PROTOCOL}://${APP_HOST_NAME}/api/${collectionName}/${userDetailId}`,
    data: {
      ...object
    },
    headers: {
      Authorization: `Bearer ${token}`
    }
  }).then((response) => {
    return response;
  }).catch((e) => {
    Sentry.captureException('Error - PUT endpoint - user profile', e);
    return e.response;
  });
}

// TODO: #BOOKMARKREFACTOR
export async function editBookmarks(object, userDetailId) {
  const token = getJwtTokenFromClient().authToken;
  return await axios({
    method: 'put',
    url: `${PROTOCOL}://${APP_HOST_NAME}/api/${collectionName}/${userDetailId}`,
    data: {
      ...object
    },
    headers: {
      Authorization: `Bearer ${token}`
    }
  }).then((response) => {
    return response;
  }).catch((e) => {
    Sentry.captureException('Error - PUT endpoint - bookmarks', e);
    return e.response;
  });
}

// Handle Edit Profile Detail Status message
export const handleBookmarksResponse = (dispatch, response) => {
  switch (response) {
    case 200:
      openSnackbar(dispatch, {
        isOpen: true,
        message: "¡Bookmark actualizado correctamente!",
        severity: "success",
      });
      break;
    default:
      openSnackbar(dispatch, {
        isOpen: true,
        message: "Hubo un error. Inténtalo nuevamente. Si el error persiste, manda un correo a soporte@vincoed.com",
        severity: "error",
      });
      break;
  }
}

// Handle Edit Profile Detail Status message
export const handleEditUserResponse = (dispatch, response) => {
  isLoading(dispatch, { loading: false });
  switch (response.status) {
    case 200:
      openSnackbar(dispatch, {
        isOpen: true,
        message: "¡Tus datos se han actualizado correctamente!",
        severity: "success",
      });
      break;
    default:
      openSnackbar(dispatch, {
        isOpen: true,
        message: "Hubo un error al enviar tus datos. Inténtalo nuevamente. Si el error persiste, manda un correo a soporte@vincoed.com",
        severity: "error",
      });
      break;
  }
}

// Handle login against status codes
export const handleLoginResponse = async (dispatch, response, email) => {
  isLoading(dispatch, { loading: false });
  finishLoginProgress(dispatch);
  if (response) {
    switch (response.status) {
      case 200:
        willLogIn(dispatch, response.data);
        isLoginSuccessfull(dispatch);
        //Segment tracking
        trackLogIn(response.data); 
        break;
      /* case 400:
        resetLoginProgress(dispatch);
        setErrorAtLogin(dispatch, returnMessageError(response.data));
        // #HACK send modal
        !response.data?.message[0]?.messages[0]?.oliphant && setChangePasswordModal(dispatch, true);
        // #HACK send modal
        break;
      case 500:
        resetLoginProgress(dispatch);
        setErrorAtLogin(dispatch, returnMessageError('Hubo un error al enviar tus datos. Inténtalo nuevamente. Si el error persiste, manda un correo a soporte@vincoed.com'));
        break; */
      default:
        resetLoginProgress(dispatch);
        setErrorAtLogin(dispatch, returnMessageError(response.data));
        !response.data?.message[0]?.messages[0]?.oliphant && setChangePasswordModal(dispatch, true);
        const error = new Error(`Email: ${email} , Status Code: ${response} `)
        Sentry.captureException('Mystery error - Login, default', error);
        break;
    }
  } else {
    resetLoginProgress(dispatch);
    //Return message error with null will return folllow
    setErrorAtLogin(dispatch, returnMessageError(null));
    const error = new Error(`Email: ${email}`)
    Sentry.captureException('Mystery error - Login, Else', error);
  }
}

/**
 * Session middleware to be used on getServerSideProps before fetching any data from backend
 * 
 * @param {*} next 
 * @returns 
 */
export const withSession = (next) => {
  return async (ctx) => {
    try {
      if (getJwtToken(ctx)) {
        return await next(ctx);
      } else {
        throw new Error("error fetching jwt token");
      }
    } catch (err) {
      removeCookies(ctx);
      return Promise.resolve({ redirect: { destination: '/login', permanent: false } });
    }
  };
}
