import { useContext } from "react";
import {
  Grid,
  Link,
  Typography
} from "@material-ui/core";
import GeneralButton from '../GeneralButton';
import { signupStyles } from "./styles";

import { store } from '../../context/store';
import {
  actionsForNextButton,
} from '../../lib/signup';

export const WelcomeStep = () => {
  const { state, dispatch } = useContext(store);
  const classes = signupStyles();

  const {
    signupReducer: {
      activeStep,
      isFormValid,
      formInfo,
    },
  } = state;

  const handleInit = () => {
    actionsForNextButton({
      stepName: activeStep.name,
      dispatch: dispatch,
      isFormValid: isFormValid[activeStep.name],
      userInfo: formInfo
    })
  }
  
  return (
    <div className={classes.centerContainer}>
      <div className={classes.paddedLogoContainer} style={{ paddingTop: '30%', paddingBottom: '10%' }}>
        <img className={classes.bigImageFirstContainer} src={`${process.env.CDN_HOST}/vinco_logo.png`} />
      </div>
      <Typography className={classes.titleFirst}  component="h1" variant="h5">
        Crea tu cuenta gratuita
      </Typography>
      <Typography className={classes.subTitleFirst} variant="overline" gutterBottom>
        Accede a programas de preparatoria, carrera profesional, educación continua, idiomas ¡y más!
      </Typography>
      <GeneralButton
        button={{
            text: "Iniciar registro",
            type: "go",
            func: () => handleInit()
        }} />
      <Grid container justifyContent="center" style={{ marginTop: '3rem' }}>
        <Grid item>
          Si ya tienes cuenta,{` `}
          <Link color="secondary" href="/login" variant="body1" >
            Inicia sesión aquí
          </Link>
        </Grid>
      </Grid>
    </div>
  )
}